module.exports = [{
      plugin: require('/Users/jonas/developer/soundpump/new_soundpump/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/jonas/developer/soundpump/new_soundpump/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"head":true,"trackingId":"UA-44505412-1"},
    },{
      plugin: require('/Users/jonas/developer/soundpump/new_soundpump/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
